<template>
  <v-container fluid>
    <v-card-text v-if="profile && !profile.betaDisclaimerDismissed">
      <v-alert color="secondary" text border="left">
        <v-card-title class="pt-0 pr-0">
          <v-icon color="secondary" left>
            mdi-alert
          </v-icon>
          <strong>Beta Disclaimer</strong>
          <v-spacer />
          <v-btn
            :loading="dismissing"
            icon
            @click="dismiss"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <p>As fireful is in beta stage, it is very likely that you run into issues and encounter missing functionality. Please help me reach a useable product by giving me feedback!</p>
        </v-card-text>
      </v-alert>
    </v-card-text>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { db } from '@/plugins/firebase'
export default {
  name: 'BaseLayout',
  data: () => ({
    dismissing: false
  }),
  computed: {
    ...mapState(['user', 'profile'])
  },
  methods: {
    tidio() {
      window.tidioChatApi.open()
    },
    async dismiss() {
      this.dismissing = true
      await db.collection('profiles').doc(this.user.uid).set({
        betaDisclaimerDismissed: true
      })
      this.dismissing = false
    }
  }
}
</script>
